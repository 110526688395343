import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useUser } from './UserContext';
import './Page.css'; // Import the CSS file
import axios from 'axios'; // Import axios for API calls
import EarningsDisplay from './EarningsDisplay'; // Import the new component
import { API_BASE_URL, fetchEarningsAndKeepData } from './api'; // Import API_BASE_URL

 // Get the current month and year
 const currentDate = new Date();
 const currentMonth = currentDate.toLocaleString('default', { month: '2-digit' }); // Get full month name
 const currentYear = currentDate.getFullYear(); // Get current year
 const currentMonthYearString = `${currentYear}_${currentMonth}`; // Create string in the format yyyy_mm

function Records() {
  const navigate = useNavigate();
  const { signOut } = useAuthenticator();
  const { userAttributes } = useUser();
  const [monthlyLinks, setMonthlyLinks] = useState([]); // State to hold monthly links
  const [totalEarnings, setTotalEarnings] = useState(null); // Initialize totalEarnings state
  const S3_USER_INFO_ATTRIBUTES_PATH = `${userAttributes.state}/${userAttributes.id}/user_info_attributes.json`;
  const [keep, setKeep] = useState(0.4); // State to hold the "keep" data
  const [referral, setReferral] = useState(""); // State to hold the "referral" data
  // const [referralKeep, setReferralKeep] = useState(0.1); // State to hold the "referral_keep" data
  // const [referralMonthlyCap, setReferralMonthlyCap] = useState(1000); // State to hold the "referral_monthly_cap" data
  const [loading, setLoading] = useState(true); // State to manage loading status

  const handleBack = () => {
    navigate('/');
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/login');
  };

  // Fetch monthly databases and check for userAttributes.id
  useEffect(() => {
    const fetchMonthlyDatabases = async () => {
      setLoading(true); // Set loading to true before fetching data
      try {
        const schema = userAttributes.id === '0' ? 'user_performance_test' : 'user_performance'; // Conditional schema assignment
        const response = await axios.get(`${API_BASE_URL}/api/monthly-databases?schema=${schema}`);
        console.log('Monthly databases response:', response.data);
        const databases = response.data; // Assuming the response contains an array of database names
        const links = [];

        for (const db of databases) {
          const result = await axios.get(`${API_BASE_URL}/api/check-id/${schema}/${db}/${userAttributes.id}`); // Check if id exists in the db
          console.log(`Check ID response for ${db}:`, result.data); // Log the response for each check-id API call
          if (result.data.exists) { // Assuming the response has an 'exists' field
            links.push(db); // Add the db name to links if id exists
          }
        }
        setMonthlyLinks(links); // Update state with found links

        // Call the new function to fetch earnings and keep data
        const data = await fetchEarningsAndKeepData(userAttributes.id, currentMonthYearString, S3_USER_INFO_ATTRIBUTES_PATH, 'total'); // Use userAttributes.id if needed
        setTotalEarnings(data.totalEarnings); // Update state with the total earnings
        setKeep(data.keep); // Set the "keep" data to state
        setReferral(data.referral); // Set the "referral" data to state
        // setReferralKeep(data.referral_keep); // Set the "referral_keep" data to state, default to 0.1 if not found
        // setReferralMonthlyCap(data.referral_monthly_cap); // Set the "referral_monthly_cap" data to state, default to 1000 if not found
      } catch (error) {
        console.error('Error fetching databases:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };

    fetchMonthlyDatabases();
  }, [userAttributes.id, S3_USER_INFO_ATTRIBUTES_PATH]); // Add S3_USER_INFO_ATTRIBUTES_PATH to dependencies

  console.log('Total Earnings:', totalEarnings); // Log the value of totalEarnings

  if (loading) { // Check if loading
    return <h1>Loading...</h1>; // Render loading message
  }

  return (
    <div className="page-container">
      <h2 className="page-h2">Records</h2>
      <div className="page-buttons">
        <button onClick={handleBack}>Back</button>
        <button onClick={handleSignOut}>Sign out</button>
      </div>

      <EarningsDisplay 
        totalEarnings={totalEarnings} 
        keep={keep}
        monthYearString={currentMonthYearString}
        referral={referral}
        current={true}
      />

      {monthlyLinks && monthlyLinks.length > 0 ? (
        <div className="historical-records-container" style={{ textAlign: 'center', margin: '0 auto', minWidth: '150px', marginTop: '30px'}}>
          <h3 style={{ textAlign: 'left', marginLeft: '0px', fontWeight: 'normal', fontSize: '1.0em', textDecoration: 'underline'}}>Detailed Records:</h3> {/* Title aligned left with increased margin */}
          <div className="monthly-links" style={{ textAlign: 'right', marginRight: '0px' }}>
            {monthlyLinks.map(link => (
              <div key={link}>
                <Link to={`/records/${link}`}>{link}</Link>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="historical-records-container" style={{ textAlign: 'center', margin: '0 auto', minWidth: '150px', marginTop: '30px'}}>
          <p>There are currently no records to display, performance of software will be shown upon starting BetPlus services!</p>
        </div>
      )}
    </div>
  );
}

export default Records;
