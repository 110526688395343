import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { UserProvider } from './UserContext';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import Home from './Home';
import PrivateRoute from './PrivateRoute';
import AccountManager from './AccountManager';
import Records from './Records';
import Tutorials from './Tutorials';
import RecordDetail from './RecordDetail';
import './Auth.css';

Amplify.configure(awsExports);

function App() {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);
  }, []);

  if (!isReady) {
    return null; // You can replace this with a loading spinner if desired
  }

  return (
    <Authenticator.Provider>
      <UserProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/account-manager"
              element={
                <PrivateRoute>
                  <AccountManager />
                </PrivateRoute>
              }
            />
            <Route
              path="/records"
              element={
                <PrivateRoute>
                  <Records />
                </PrivateRoute>
              }
            />
            <Route
              path="/records/:recordName"
              element={
                <PrivateRoute>
                  <RecordDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/tutorials"
              element={
                <PrivateRoute>
                  <Tutorials />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </Router>
      </UserProvider>
    </Authenticator.Provider>
  );
}

export default App;
