import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EarningsDisplay, { formatCurrency } from './EarningsDisplay';
import { fetchEarningsAndKeepData } from './api';
import { useUser } from './UserContext';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { downloadData } from '@aws-amplify/storage';

function RecordDetail() {
  const { recordName } = useParams();
  const { userAttributes } = useUser();
  const [totalEarnings, setTotalEarnings] = useState(null);
  const [keep, setKeep] = useState(0.4);
  const [referral, setReferral] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { signOut } = useAuthenticator();
  const [dailyEarnings, setDailyEarnings] = useState([]);

  // Extract year and month from recordName
  const [year, month] = recordName.split('_').map(Number); // Split and convert to numbers

  // Construct the S3 path using userAttributes
  const S3_USER_INFO_ATTRIBUTES_PATH = `${userAttributes.state}/${userAttributes.id}/user_info_attributes.json`;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const earnings = await fetchEarningsAndKeepData(userAttributes.id, recordName, S3_USER_INFO_ATTRIBUTES_PATH, 'total'); // Fetch total earnings
        setTotalEarnings(earnings.totalEarnings);
        setKeep(earnings.keep);
        setReferral(earnings.referral);

        const daysInMonth = new Date(year, month, 0).getDate(); // Calculate days in month
        const dailyEarningsData = [];
        for (let day = 1; day <= daysInMonth; day++) {
          const dayString = day.toString().padStart(2, '0'); // Format day as 'dd'
          const fullDate = `${month.toString().padStart(2, '0')}_${dayString}_${year}`; // Define fullDate in mm_dd_yyyy format
          const dailyEarnings = await fetchEarningsAndKeepData(userAttributes.id, recordName, S3_USER_INFO_ATTRIBUTES_PATH, dayString); // Fetch daily earnings
          const pnl = dailyEarnings.totalEarnings != null ? formatCurrency(dailyEarnings.totalEarnings) : 'no data/software did not run'; // Apply formatCurrency
          
          // Define wagerText before pushing to dailyEarningsData
          let wagerText = null; // Initialize wagerText
          if (dailyEarnings.totalEarnings != null) {
            try {
              const wagerData = await downloadData({ path: `wager_files/${userAttributes.id}/${fullDate}.json` });
              console.log('Wager Data:', wagerData);
              
              // Await the result promise to get the actual data
              const result = await wagerData.result; // Resolve the promise
              const bodyText = await result.body.text(); // Call text() on the Blob
              wagerText = bodyText ? bodyText : null; // Assign the text or null if empty
              
              console.log('Wager Text:', wagerText);
            } catch (error) {
              console.error('Error downloading wager data:', error);
            }
          }

          dailyEarningsData.push({ 
            day: dayString, 
            pnl: pnl, 
            wager_json: wagerText // Use wagerText here
          });
        }
        setDailyEarnings(dailyEarningsData);

      } catch (error) {
        console.error('Error fetching earnings data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [recordName, userAttributes, S3_USER_INFO_ATTRIBUTES_PATH, month, year]);

  const handleBack = () => {
    navigate('/records');
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/login');
  };

  if (loading) {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="page-container">
      <div className="page-buttons" style={{ marginTop: '40px' }}>
        <button onClick={handleBack}>Back</button>
        <button onClick={handleSignOut}>Sign out</button>
      </div>
      <EarningsDisplay 
        totalEarnings={totalEarnings}
        keep={keep}
        monthYearString={recordName}
        referral={referral}
        current={false}
      />
      <table style={{ border: '1px solid black', borderCollapse: 'collapse', margin: '30px 0 70px 0' }}>
        <tbody>
          {dailyEarnings.map((entry) => (
            <tr key={entry.day} style={{ borderBottom: '1px solid black' }}>
              <td style={{ padding: '0 10px', borderRight: '1px solid black' }}>{entry.day}</td>
              <td style={{ textAlign: 'right', padding: '0 10px', borderRight: '1px solid black' }}>{entry.pnl}</td>
              <td style={{ padding: '0 10px' }}>
                {entry.wager_json ? (
                  <a 
                    href={URL.createObjectURL(new Blob([entry.wager_json], { type: 'application/json' }))} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    View Wagers
                  </a>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RecordDetail; 