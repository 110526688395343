import React from 'react';
import { format } from 'date-fns';

export function formatCurrency(amount) {
    return `${amount < 0 ? '-' : ''}$${Math.abs(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`; // Format amount as currency
}

// Function to convert 'yyyy_mm' to 'Month yyyy'
export const formatDateString = (dateString) => {
    const [year, month] = dateString.split('_'); // Split the input string
    const date = new Date(year, month - 1); // Create a date object (month is 0-indexed)
    return format(date, 'MMMM yyyy'); // Format the date to 'Month yyyy'
};

function EarningsDisplay({ totalEarnings, keep, monthYearString, referral, current }) {
  return (
    <>
      {totalEarnings !== null && totalEarnings !== undefined && ( // Check if totalEarnings is not null or undefined
        <>
          <h3 className="page-h3" style={{ textAlign: 'center', marginTop: '40px', marginBottom: '0px' }}>
            Your {formatDateString(monthYearString)} {current ? 'Running' : ''} Earnings: 
            <span style={{ 
              backgroundColor: totalEarnings > 0 ? '#33FF64' : 'transparent',
              marginLeft: '8px' // Add margin to the left for spacing
            }}>
              {formatCurrency(keep * totalEarnings)}
            </span>
          </h3>
          {totalEarnings < 0 && ( // Check if totalEarnings is negative
            <p style={{ textAlign: 'left', marginTop: '0px', marginBottom: '0px', fontSize: '0.8em' }}>
              *In the event total earnings are negative at the end of the month,<br />
              you will be 100% reimbursed for these losses.
            </p> // Add note if earnings are negative
          )}
          <p className="page-text" style={{ textAlign: 'left', marginTop: '30px' }}>
            Total Earnings: {formatCurrency(totalEarnings)}<br />
            Client Earnings: {formatCurrency(keep * totalEarnings)}<br />
            Amount Owed: {formatCurrency((1 - keep) * totalEarnings)}<br />
            {referral && ( // Check if referral is truthy
              <div>
                <p style={{ margin: 0 }}>Referral Earnings: {formatCurrency(0.1 * totalEarnings)} ({referral})</p>
              </div>
            )}
          </p>
        </>
      )}
    </>
  );
}

export default EarningsDisplay; 