import axios from 'axios';
import { downloadData } from '@aws-amplify/storage';

// Export the API_BASE_URL
export const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'https://api.betplus.tech:3001'
  : 'http://34.217.64.105:3001';

export const fetchEarningsAndKeepData = async (userId, currentMonthYearString, s3Path, day) => {
  const earnings = {};
  try {
    // Determine the database name based on userId
    const databaseName = userId === '0' ? 'user_performance_test' : 'user_performance';
    const pnlResponse = await axios.get(`${API_BASE_URL}/api/get-pnl?database_name=${databaseName}&table_name=${currentMonthYearString}&day=${day}&user_id=${userId}`);
    earnings.totalEarnings = pnlResponse.data.pnl; // Update state with the total earnings
  } catch (error) {
    console.error('Error fetching PNL data:', error);
    earnings.totalEarnings = null; // Set totalEarnings to null if the table does not exist
  }

  // Fetch the "keep" data from S3
  try {
    const downloadTask = downloadData({ path: s3Path }); // Use the provided S3 path
    const { body } = await downloadTask.result;
    if (!body) {
      throw new Error('Download succeeded but response body is empty.');
    }
    const text = await body.text();
    const data = JSON.parse(text);
    earnings.keep = data.keep; // Set the "keep" data to state
    earnings.referral = data.referral || ""; // Set the "referral" data to state, default to "" if not found
  } catch (error) {
    console.error('Error fetching keep data from S3:', error);
  }

  return earnings; // Return the earnings data
}; 