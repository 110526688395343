import React, { useState, useEffect, useMemo } from 'react'; // Import useState for managing state
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { downloadData, uploadData } from '@aws-amplify/storage';
import { useUser } from './UserContext';
import './Page.css'; // Import the CSS file

// New InputContainer component
const InputContainer = ({ label, index, onInputChange, isSaved, additionalText, data }) => {
    const [isChecked, setIsChecked] = useState(data.active);
    const [inputValue1, setInputValue1] = useState(data.username);
    const [inputValue2, setInputValue2] = useState(data.password);
  
    const handleCheckboxChange = () => {
      const newChecked = !isChecked;
      setIsChecked(newChecked);
      onInputChange(index, { ...data, active: newChecked, username: inputValue1, password: inputValue2 });
    };
  
    const handleInputChange1 = (value) => {
      setInputValue1(value);
      onInputChange(index, { ...data, active: isChecked, username: value, password: inputValue2 });
    };
  
    const handleInputChange2 = (value) => {
      setInputValue2(value);
      onInputChange(index, { ...data, active: isChecked, username: inputValue1, password: value });
    };
  
    return (
      <div className="input-container">
        <span className="book-label">{label}{additionalText}:</span>
        <div className="input-fields">
          <label className="checkbox">
            <input 
              type="checkbox" 
              checked={isChecked} 
              onChange={handleCheckboxChange} 
              style={{ 
                accentColor: isSaved ? 'black' : 'initial',
              }} 
            />
          </label>
          <input 
            type="text" 
            className="input-field" 
            placeholder="Enter your email/username" 
            value={inputValue1}
            onChange={(e) => handleInputChange1(e.target.value)} 
            style={{ fontWeight: isSaved ? 'bold' : 'normal' }} 
          />
          <input 
            type="text" 
            className="input-field" 
            placeholder="Enter your password" 
            value={inputValue2}
            onChange={(e) => handleInputChange2(e.target.value)} 
            style={{ fontWeight: isSaved ? 'bold' : 'normal' }} 
          />
        </div>
      </div>
    );
  };
  
function AccountManager() {
    const navigate = useNavigate();
    const { signOut } = useAuthenticator();
    const { userAttributes, loading } = useUser();

    // Memoize labels based on userAttributes.state
    const labels = useMemo(() => {
        if (userAttributes.state === 'AZ') {
            return ['bet365', 'BetMGM', 'BetRivers', 'Caesars', 'DraftKings', 'FanDuel', 'HardRock', 'PENN'];
        } else if (userAttributes.state === 'CO') {
            return ['bet365', 'BetMGM', 'BetRivers', 'Caesars', 'DraftKings', 'FanDuel', 'PENN'];
        } else {
            return []; // Empty list for any other state
        }
    }, [userAttributes.state]); // Dependency on userAttributes.state

    // Define the S3 path as a constant using userAttributes
    const S3_USER_INFO_CLIENT_PATH = `${userAttributes.state}/${userAttributes.id}/user_info_client.json`;

    // State variables
    const [isInputChanged, setIsInputChanged] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [inputValues, setInputValues] = useState(null); // Initialize as null to handle loading state
    
    const handleBack = () => {
      navigate('/');
    };

    const handleSignOut = async () => {
      await signOut();
      navigate('/login');
    };

    // Fetch JSON data from S3 on component mount
    useEffect(() => {
      const fetchData = async () => {
        console.log('FetchData: Initiating data fetch.');
        try {
          const downloadTask = downloadData({ path: S3_USER_INFO_CLIENT_PATH });
          const { body } = await downloadTask.result;
          if (!body) {
            throw new Error('Download succeeded but response body is empty.');
          }
          const text = await body.text();
          const data = JSON.parse(text);
          console.log('FetchData: Parsing JSON data.');
          console.log('FetchData: Parsed data:', data);

          // Ensure that inputValues includes all labels from client_settings
          const initialValues = labels.reduce((acc, label) => {
            acc[label] = data.client_settings[label] || { active: false, username: '', password: '' };
            return acc;
          }, {});
          
          setInputValues(initialValues);
          console.log('Fetched input values:', initialValues); // Log the fetched data
        } catch (error) {
          console.error('Error fetching data from S3:', error);
          // Initialize with default values in case of error
          const defaultValues = labels.reduce((acc, label) => {
            acc[label] = { active: false, username: '', password: '' };
            return acc;
          }, {});
          setInputValues(defaultValues);
        }
      };

      fetchData();
    }, [S3_USER_INFO_CLIENT_PATH, labels]); // labels is now memoized

    // Handle input changes
    const handleInputChange = (label, updatedData) => {
      setInputValues(prev => ({
        ...prev,
        [label]: updatedData,
      }));
      setIsInputChanged(true);
    };

    // Handle save button click
    const handleSave = async () => {
      try {
        console.log('Saving...');
        // Wrap inputValues in an object with client_settings key
        const jsonString = JSON.stringify({ client_settings: inputValues }, null, 2);

        console.log('Saving2...');
        console.log(jsonString);
        console.log(typeof jsonString); // Should output 'string'

        // Upload the updated JSON back to S3
        const uploadTask = uploadData({
            path: S3_USER_INFO_CLIENT_PATH,
            data: jsonString,
            options: {
              contentType: 'application/json',
            },
        });

        console.log('Saving3...');

        // Await the result of the upload task
        await uploadTask.result;

        console.log('Saving4...');

        setIsInputChanged(false);
        setIsSaved(true);
        // Reset the saved state after a short delay (optional)
        setTimeout(() => setIsSaved(false), 2000);
        alert('Data saved successfully!');
      } catch (error) {
        console.error('Error saving data to S3:', error);
        alert('Failed to save data. Please try again.');
      }
    };

    if (loading || inputValues === null) {
        return <h1>Loading...</h1>;
    }

    return (
      <div className="page-container">
        <h2 className="page-h2">Account Manager</h2>
        <div className="page-buttons">
          <button onClick={handleBack}>Back</button>
          <button 
            className="save-button" 
            onClick={handleSave} 
            disabled={!isInputChanged} 
          >
            Save
          </button>
          <button onClick={handleSignOut}>Sign out</button>
        </div>
        <p className="page-text">
          Mark sportsbook as active to enable it for BetPlus services.<br />
          Be sure to save any changes!
        </p>
        {/* Instantiate multiple InputContainer components */}
        <div style={{ marginBottom: '100px' }}>
          {labels.map((label) => (
            <InputContainer 
              key={label} 
              label={label} 
              index={label} // Use label as index
              onInputChange={handleInputChange}
              isSaved={isSaved} 
              additionalText={label === 'PENN' ? ' (ESPN)' : ''} // Add additional text for PENN
              data={inputValues[label]} // Pass existing data
            />
          ))}
        </div>
      </div>
    );
  }

export default AccountManager;
